<template>
    <main class="main-page"  id="">
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div :class="{ 'card ': !isSubPage }" class="">
                            <div class="grid gap-3 align-items-center justify-content-between">
                                <div class="col text-500 font-bold text-sm">
                                    Belum memiliki akun?
                                </div>
                                <div class="col-fixed text-right">
                                    <router-link to="/index/register">
                                        <Button icon="pi pi-user" label="Daftar Disini" class="p-button-success" />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div :class="{ 'card ': !isSubPage }" class="">
                            <NiceImage class="img-fluid" src="images/banner_1080x420-2.jpg"   style="max-width:100%;width:100vw;" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="">
                            <div class="">
                                <ProdukDashboardPage ref="produkListPage"  :limit="10" :show-header="true" :show-breadcrumbs="true" :show-footer="false" :paginate="false" page-store-key="USER-PRODUK-DASHBOARD" is-sub-page>
                                </ProdukDashboardPage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Search
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useListPage } from 'src/composables/listpage.js';
	import ProdukDashboardPage from "../produk/dashboard.vue";
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'PRODUK-NO_LOGIN',
		},
		pageName: {
			type: String,
			default : 'produk',
		},
		routeName: {
			type: String,
			default: 'produkno_login',
		},
		apiPath: {
			type: String,
			default: 'produk/no_login',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "Data Tidak Ditemukan",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Yakin ingin menghapus data ini?",
		},
		msgAfterDelete: {
			type: String,
			default: "Data berhasil dihapus",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	const auth = useAuth();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const {  loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	function getActionMenuModel(data){
		return [
		{
			label: "View",
			to: `/produk/view/${data.id}`,
			icon: "pi pi-eye",
			visible: auth.canView('produk/view')
		},
		{
			label: "Edit",
			to: `/produk/edit/${data.id}`,
			icon: "pi pi-pencil",
			visible: auth.canView('produk/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash",
			visible: auth.canView('produk/delete')
		}
	]
	}
	
	onMounted(()=>{ 
		const pageTitle = "Produk";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
